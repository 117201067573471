/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
:host {
    --overflow: overlay !important;
}

.label-stacked.sc-ion-label-md-h {
    -webkit-transform: translateY(50%) scale(0.85);
    transform: translateY(50%) scale(.85);
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Ubuntu';
    font-weight: bold;
}

h1 {
    font-size: 30px;
}

h3 {
    font-size: 18px;
}

h3.title {
    margin-top: 0;
    color: #000000;
}

p,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
    font-size: 14px;
}

a {
    cursor: pointer;
}

.text-bold {
    font-family: 'OpenSansBold' !important;
}

.text-blue {
    color: #48658c !important;
}

.inner-scroll {
    color: #666666;
}


/* component-navbar {
    position: absolute;
    width: 100%;
    top: 0%;
}

ion-tab-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
} */

ion-content::part(scroll) {
    overflow-y: overlay !important;
    /* overscroll-behavior: auto; */
}

.alert-message.sc-ion-alert-md {
    overflow-y: scroll !important;
}

ion-grid {
    max-width: 1120px;
    margin: 0 auto;
}

ion-card:not(.loader, .error) {
    box-shadow: none;
}

ion-card.loader {
    img {
        width: 80px;
        display: block;
        margin: 0 auto;
    }
}

ion-button {
    font-family: 'Ubuntu';
    font-weight: bold;
    --padding-bottom: 22px;
    --padding-top: 22px;
    font-size: 15px;
}

ion-button.button-small {
    --padding-bottom: 12px;
    --padding-top: 12px;
}

ion-item {
    --ion-item-background: none;
}

ion-slide {
    width: 100%;
}

.reducedModal {
    align-items: center;
    justify-content: center;
    &::part(content) {
        height: 550px;
        width: 80%;
    }
}

.reducedAlert {
    align-items: center;
    justify-content: center;
    &::part(content) {
        height: 200px;
        width: 80%;
    }
}

.reducedModalPermission {
    background-color: #000000;
}

@media screen and (min-width: 768px) and (max-width: 1438px) {
    .reducedModal {
        &::part(content) {
            height: 570px;
            width: 60%;
        }
    }
}

@media screen and (min-width: 991px) {
    .reducedModal {
        &::part(content) {
            width: 32%;
        }
    }
    .reducedAlert {
        &::part(content) {
            width: 35%;
        }
    }
}

@media screen and (min-width: 1250px) {
    .reducedModal {
        &::part(content) {
            width: 25%;
        }
    }
}

@media screen and (min-width: 1439px) and (max-width: 1442px) {
    .reducedModal {
        &::part(content) {
            height: 580px;
            width: 32%;
        }
    }
}

@media screen and (min-width: 1443px) {
    .reducedModal {
        &::part(content) {
            height: 570px;
        }
    }
    .featuredModal {
        &::part(content) {
            height: 670px;
        }
    }
}

@media screen and (min-width: 1920px) {
    .reducedModal::part(content) {
        height: 670px;
    }
}

@media screen and (max-width: 991px) {
    ion-col.nomargin {
        padding-left: 2px;
        padding-right: 2px;
    }
}